<template>
  <div
    class="fixed right-0 top-1/2 z-50 hidden -translate-y-1/2 sm:block"
    data-el="ui-floating-icons"
  >
    <template
      v-for="icon in props.icons"
      :key="icon.link"
    >
      <component
        :is="getComponent(icon)"
        v-if="icon?.visible != null ? icon.visible : true"
        :href="icon.link"
        :target="icon.target"
        class="group mb-px flex size-10 items-center justify-center"
        :class="{
          'bg-secondary text-white': icon.icon === 'chat',
          'bg-white text-secondary': icon.icon !== 'chat',
        }"
        @click="icon?.action && icon.action()"
      >
        <UiIcon
          :name="icon.icon"
          width="20"
          height="20"
          class="transition-transform group-hover:scale-125"
          :class="[{ 'cursor-pointer': icon?.action }]"
        />
      </component>
    </template>
  </div>
</template>

<script setup lang="ts">
import UiIcon from '../UiIcon/UiIcon.vue'
import type { UiFloatingIcon } from './UiFloatingIcons.types'

interface UiFloatingIcons {
  icons: UiFloatingIcon[]
}
const props = defineProps<UiFloatingIcons>()

function getComponent(icon: UiFloatingIcon) {
  return icon?.link
    ? resolveComponent('NuxtLink')
    : 'span'
}
</script>
